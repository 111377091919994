.cart-container {
  position: relative;
}
.cart-icon-white,
.cart-text {
  display: none;
}

.total-count {
  background-color: #f01500;
  color: var(--main-white);
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .cart-icon-white,
  .cart-text {
    display: block;
  }
  .cart-icon-black {
    display: none;
  }
  .cart-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    min-width: 100px;
    max-width: 190px;
    min-height: 35px;
    padding: 8px 32px;
    color: var(--main-white);
    background-color: var(--main-button-color);
    border: none;
    cursor: pointer;
    position: relative;
  }
  .cart-container {
    display: flex;
    justify-content: center;
  }

}