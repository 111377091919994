/* General container */
.macaron-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* The macaron with the SVG as background */
.macaron {
  position: relative;
  width: 75px;
  height: 75px;
  background-image: url('../../../assets/icon_empty.svg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

/* Text inside the macaron */
.macaron-text {
  position: absolute;
  color: white;
  font-family: var(--main-font-family)
}

.points-value {
  display: block;
  font-size: 20px;
  font-weight: 600;
}

.points-label {
  display: block;
  font-size: 12px;
  font-weight: 600;
}

/* Responsive breakpoints */

/* Large screens */
@media (min-width: 1200px) {
  .macaron {
    width: 70px;
    height: 70px;
  }

  .points-value {
    font-size: 14px;
  }

  .points-label {
    font-size: 12px;
  }
}

/* Medium screens (laptop, tablets) */
@media (min-width: 768px) and (max-width: 1199px) {
  .macaron {
    width: 55px;
    height: 55px;
  }

  .points-value {
    font-size: 10px;
  }

  .points-label {
    font-size: 9px;
  }
}

/* Small screens (smartphones) */
@media (max-width: 767px) {
    .macaron {
        width: 50px;
        height: 50px;
      }
    
      .points-value {
        font-size: 9px;
      }
    
      .points-label {
        font-size: 8px;
      }
      .macaron-text {
        left: 25%;
      }
}

