/* const.css */
:root {
  /* COLORS */
    --admin-primary-color: #007bff;
    --secondary-color: #6c757d;
    --success-color: #28a745;
    --error-color: #dc3545;
    --main-black: #000000;
    --main-white: #FFFFFF;
    --main-lighter-grey: #F5F6F8;
    --main-light-grey: #E7F3EF;
    --main-grey: #EDEDF2;
    --main-dark-grey: #999EA3;
    --main-green: #2F929D;
    --main-orange: #F0B435;
    --main-red: #f01500;
    --main-button-color: #34303E;
    --orange-gradient-dark: linear-gradient(90deg,  #F28C27 0%, rgb(249 189 27 / 100%) 50%, #F9BD1B 100%);
    --orange-gradient-light: linear-gradient(90deg,  #F9BD1B 0%, rgb(249 189 27 / 100%) 50%, #FFDE84 100%);

    /* FONT */
    --main-font-family: 'Spartan', sans-serif;
  }

  .font__title--h1 {
    font-weight: bolder;
    font-size: 30px;
    line-height: 34px;
    font-family: var(--main-font-family);
  }

  .font__title--h2 {
    font-weight: bolder;
    font-size: 20px;
    line-height: 24px;
    font-family: var(--main-font-family);
  }
  .font__title--h3 {
    font-weight: bolder;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--main-font-family);
  }
  
  .font__body--small {
    font-size: 11px;
    line-height: 14px;
    font-family: var(--main-font-family);
  }
  .font__body--bold-small {
    font-size: 11px;
    line-height: 14px;
    font-family: var(--main-font-family);
    font-weight: 700;
  }
  .font__body--medium {
    font-size: 12px;
    line-height: 22px;
    font-family: var(--main-font-family);
  }
  .font__body--bold-medium {
    font-size: 12px;
    line-height: 22px;
    font-family: var(--main-font-family);
    font-weight: 700;
  }
  .font__body--large {
    font-size: 15px;
    line-height: 22px;
    font-family: var(--main-font-family);
    
  }
  .font__body--bold-large {
    font-size: 15px;
    line-height: 22px;
    font-family: var(--main-font-family);
    font-weight: 700;
  }

@media screen and (max-width: 1280px) {
  .font__title--h1 {
      font-size: 28px;
      line-height: 32px;
  }

  .font__title--h2 {
      font-size: 18px;
      line-height: 22px;
  }

  .font__title--h3 {
      font-size: 16px;
      line-height: 22px;
  }

  .font__body--small,
  .font__body--bold-small {
      font-size: 9px;
      line-height: 12px;
  }

  .font__body--medium,
  .font__body--bold-medium {
      font-size: 11px;
      line-height: 20px;
  }

  .font__body--large,
  .font__body--bold-large {
      font-size: 13px;
      line-height: 20px;
  }
}

@media screen and (max-width: 960px) {
  .font__title--h1 {
      font-size: 26px;
      line-height: 30px;
  }

  .font__title--h2 {
      font-size: 20px;
      line-height: 20px;
  }

  .font__title--h3 {
      font-size: 14px;
      line-height: 20px;
  }

  .font__body--small,
  .font__body--bold-small {
      font-size: 9px;
      line-height: 10px;
  }

  .font__body--medium,
  .font__body--bold-medium {
      font-size: 12px;
      line-height: 18px;
  }

  .font__body--large,
  .font__body--bold-large {
      font-size: 12px;
      line-height: 18px;
  }
}

@media screen and (max-width: 768px) {
  .font__title--h1 {
      font-size: 24px;
      line-height: 26px;
  }

  .font__title--h2 {
      font-size: 18px;
      line-height: 18px;
  }

  .font__title--h3 {
      font-size: 12px;
      line-height: 18px;
  }

  .font__body--small,
  .font__body--bold-small {
      font-size: 9px;
      line-height: 8px;
  }

  .font__body--medium,
  .font__body--bold-medium {
      font-size: 12px;
      line-height: 16px;
  }

  .font__body--large,
  .font__body--bold-large {
      font-size: 12px;
      line-height: 16px;
  }
}
