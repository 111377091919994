.admin-dropdown {
    position: relative;
    width: 100%;
  }
  
  .admin-dropdown-header {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid var(--admin-primary-color);
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  
  .admin-dropdown-list {
    position: absolute;
    width: 100%;
    border: 1px solid var(--admin-primary-color);
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    z-index: 10;
  }
  
  .admin-dropdown-option {
    padding: 10px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  
  .admin-dropdown-option:hover {
    background-color: var(--admin-primary-color);
    color: white;
  }