.pagination {
  margin-bottom: 16px
}
.pagination-item-list {
  display: flex;
  gap: 16px;
  cursor: pointer;
  width: 70%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: relative;
}
.pagination-item {
  display: flex;
  justify-content: center;
  min-width: 24px;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.active-page {
  color: var(--main-green);
}

.filter-active-pagination {
  margin-right: 25px
}