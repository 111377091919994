.sidebar-container
{  display: flex;
  flex-direction: column;
  width: 15%;
  min-width: 200px;
  padding-top: 10px;
  margin-top: 10px;
  background-color: var(--admin-primary-color);
  border-radius: 0 10px 0 0;
  color: var(--main-white);
}

.sidebar-item
{  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--main-white);
  border-top: 1px solid var(--main-white);
  padding-left: 25px;
}

.logo
{  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
