.modal-overlay-package {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(0.2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content-package {
    background-color: white;
    width: 50%;
    max-height: 60vh;
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .modal-header-package {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-btn-package {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body-package {
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .package-details-table {
    width: 100%;
    border-collapse: collapse;
    border: none;
  }
  
  .package-details-table-th, .package-details-table-td {
    border: none;
    padding: 8px;
    text-align: left;
    line-height: 14px;
  }

  .package-details-table-th-quantity {
    border: none;
    padding: 8px;
    text-align: center;
  }

  .package-details-table-td-quantity {
    display: flex;
    align-items: center;
    justify-content: center !important;
    border: none;
    text-align: center;
    height: 100%;
  }

  .package-details-table-td-quantity > input {
width: 35px;
height: 100%;
margin-top: 10%;
text-align: center;
  }
  

  
  .modal-body-pacakge::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-body-package::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 4px;
  }
  
  @media screen and (max-width: 1280px) {
    .modal-content-package {
      background-color: white;
      width: 60%;
      max-height: 70vh;
      padding: 25px;
    }
  }

  @media screen and (max-width: 768px) {
    .modal-content-package {
      background-color: white;
      width: 90%;
      max-height: 70vh;
      padding: 25px;
    }
  }

  @media screen and (max-width: 980px) {
    .modal-overlay-package {
      position: fixed;
      padding-top: 25%;
      align-items: flex-start;

    }
  }