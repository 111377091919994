.order-card-container {
  display: grid;
  grid-template-areas:
    "a"
    "b"
    "d";
  background-color: var(--main-white);
  padding: 16px;
  border-radius: 24px;
  gap: 16px;
  align-items: center;
}

.order-card-id-date {
  display: flex;
  grid-area: a;
}
.order-card-img-quantity {
  grid-area: b;
  display: flex;
  align-items: center;
  gap: 16px;
}

.order-card-container .main-button {
  max-width: fit-content;
  padding: 8px 40px 8px 36px;
  grid-area: d;
  justify-self: flex-end;
}
.order-card-img {
  width: 40px;
}

.order-card-status {
  margin-left: 4px;
}
.order-card-status.delivered {
  color: var(--success-color);
}
.order-card-status.shipped {
  color: var(--main-orange);
}
.order-card-status.canceled {
  color: var(--main-red);
}

.order-card-dropdown-icon {
  height: 24px;
}

.order-card-dropdown-icon {
  transition: 0.3s ease-out;
}
.order-card-dropdown-icon.is-active {
  transform: rotate(180deg);
}

/* Oder Detail display Cases */
.order-card-container.is-active {
  grid-template-areas:
  "a a"
  "e e"
  "c d";
}

.order-detail-container {
  grid-area: e;
}
.order-card-img-quantity.is-active {
  display: none;
}

.history-macaron {
  width: 60%;
}
.history-macaron  .points-value {
  height: 15px;
}
.history-macaron  .points-label {
  height: 15px;
}

@media screen and (min-width: 500px) {
  .order-card-img {
    width: 60px;
  }
  .order-card-img-quantity {
    gap: 32px;
  }
}

@media screen and (min-width: 960px) {
  .order-card-container {
    grid-template-areas:
      "a c"
      "b d";
  }
  .order-card-img-quantity {
    padding-left: 24px;
  }
}

@media screen and (min-width: 1024px) {
  .order-card-container.is-active {
    grid-template-areas:
    "a d"
    "e e";
  }
}
