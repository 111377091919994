.admin-container
{  
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.admin-content
{  
  width: 100%;
  padding: 30px;
  margin: 20px;
  border-radius: 10px 0;
  height: 100vh;
  overflow: auto;
}
