.cart-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.cart-card__info {
    padding: 0 0 30px;
}

.product-cart-img {
    width: 40px;
    height: auto;
}

.image {
    width: 15%;
    text-align: center;
}

.code {
    width: 20%;
}

.name {
    width: 30%;
}

.quantity {
    width: 20%;
}

.points {
    width: 5%;
}

.points > svg {
    width: 50px;
}

.points__icon {
    position: relative;
    display: flex;
}

.points__icon--span {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;
}

.points__icon--total {
    top: 18px;
    font-weight: 600;
}

.points__icon--points {
    top: 30px;
    font-weight: normal;
    font-size: 9px;
}
@media screen and (max-width: 1280px) { 
    .points__icon--points {
        top: 28px !important;
        left: 2px !important;
        font-weight: normal;
        font-size: 9px;
    }
}

@media screen and (min-width: 980px) {
    .product-cart-img, .points__icon {
        width: 60px;
    }

    .image {
        width: 10%;
        text-align: right;
    }

    .code {
        width: 15%;
        font-weight: 600;
        text-align: center;
    }

    .name {
        width: 35%;
    }

    .quantity {
        width: 25%;
    }

    td.quantity {
        font-size: 14px
    }

    .points {
        width: 5%;
    }

    .points > svg {
        width: 60px;
    }

    .points__icon--total {
        top: 15px;
    }
    
    .points__icon--points {
        font-size: 9px;
    }
}