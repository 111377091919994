.generic-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: var(--main-font-family);
    font-size: 14px;
    
    border: 1px solid var(--admin-primary-color);
  }
  
  .generic-table th,
  .generic-table td {
    text-align: center;
    padding: 12px 10px;
    border-bottom: 1px solid var(--admin-primary-color);
    
  }
  
  .generic-table th {
    background-color: var(--admin-primary-color);
    color: var(--main-white);
  }
  
  .generic-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
  
  .generic-table tbody tr:hover {
    background-color: #ddd;
    cursor: default;
  }
  

  
  .generic-table th:last-child,
  .generic-table td:last-child {
    border-right: none;
  }

.generic-table thead th {
    border-top: 1px solid #f0f0f0;
  }
  
  .generic-table {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .table-cell {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .generic-table td {
    position: relative;
    height: 80px;
    min-width: 150px;
    overflow: hidden;
  }
  