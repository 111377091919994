.image-library-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-top: 50px;
}

@media screen and (max-width: 1280px) {
  .image-library-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 30px;
  }
}

.label {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 12rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border: 2px dashed;
  border-radius: 0.375rem;
  appearance: none;
  cursor: pointer;
}

.label:focus {
  outline: none;
}

.border-green-500 {
  border-color: #4caf50;
}

.border-gray-300 {
  border-color: #d3d3d3;
}

.text-green-600 {
  color: #43a047;
}

.text-gray-600 {
  color: #757575;
}

.span-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.icon {
  width: 1.75rem;
}

.input {
  display: none;
}

.hidden {
  display: none;
}

.import-button {
  position: absolute;
  right: 0;
  margin-top: 50px;
}

.library-image-card {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 200px;
}

@media screen and (max-width: 1280px) {
  .library-image-card {
    height: 180px;
  }
}

.library-image {
  border: 1px dashed var(--admin-primary-color);
}

.library-image-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.confirmation-button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
}
