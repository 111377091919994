

.cart-panel-container .info-panel-icon {
  margin: 0;
  display: none;
}

.info-panel-back-button-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info-panel-icon.mobile {
  display: block;
}

.cart {
  font-weight: bold;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 30px 10px;
}

.cart__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cart__header--title {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  margin-left: 10px;
}

.cart__header--title-container {
  position: relative;
}

.cart__header--title-container > svg {
  width: 30px;
  height: auto;
}

.cart__header--back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
  max-width: 250px;
  font-size: 10px;
  font-weight: normal;
}

.cart__header--back-button > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-order {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #f01500;
  color: #fff;
  font-weight: normal;
  font-size: 10px;
}

.back-button-icon {
  width: 30px;
  height: auto;
}

.cart__cards-list {
  font-size: 17px;
  padding: 30px;
}

.cart__back-button {
  font-size: 14px;
  max-width: 300px;
  height: 46px;
  border-radius: 23px;
}

.cart__cards-list {
  background-color: #fff;
  padding: 30px 10px;
  border-radius: 15px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.cart__cards-list--agreement {
  margin: 20px 10px 0;
  margin-left: auto;
  width: 180px;
  text-align: center;
  font-size: 10px;
  font-weight: normal;
}

.agreement-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agreement-checkbox > input {
  cursor: pointer;
  margin: 0 5px 0 0;
}

.validation-button {
  width: 100%;
  font-weight: normal;
  margin: 10px 0 0;
}

.disabled-button {
  background-color: var(--main-dark-grey) !important;
  cursor: not-allowed;
}

thead {
  height: 30px;
}

td {
  vertical-align: middle;
  line-height: 1;
}

th {
  text-align: left;
}

.cart_footer {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.golden-ticket-access-info {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 80%;
}
.golden-ticket-access-label {
  color: var(--main-green);
  width: fit-content;
}

@media screen and (min-width: 980px) {
  .cart {
    padding: 30px;
  }
  .cart-panel-container .info-panel-icon svg {
    width: 40px;
    height: 40px;
  }
  /* .cart__header,
    .cart__cards-list {
        width: 70%;
    } */

  .cart__header--title {
    font-size: 22px;
    margin-left: 40px;
    flex-direction: row;
  }

  .cart__header--title-container > svg {
    width: 40px;
    height: auto;
  }

  .cart__header--back-button {
    font-size: 12px;
    height: 44px;
    border-radius: 22px;
  }

  .back-button-icon {
    margin-right: 10px;
  }

  .total-order {
    top: -5px;
    right: 10px;
    height: 20px;
    width: 20px;
    font-size: 12px;
  }

  .cart__cards-list {
    font-size: 17px;
    padding: 30px;
  }

  .cart__cards-list--agreement {
    width: 180px;
  }

  .agreement-checkbox {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  #ref,
  #quantity {
    text-align: center;
  }
}

@media screen and (min-width: 1024px) {
  .cart {
    width: 75%;
  }
  .cart-panel-container {
    display: flex;
    padding: 16px;
  }
  .cart-panel-container .info-panel {
    width: 25%;
  }
  .cart-panel-container .info-panel-icon {
    display: none;
  }
}

.info-modal-overlay-pos {
  align-items: start !important;
}
.info-modal-overlay-pos > div {
  margin-top: 10%;
}

.agreement {
  font-size: 20px;
  margin-bottom: 2px;
  cursor: pointer;
}
.input-checkbox {
  width: 18px;
  height: 18px;
}

.table-body-container {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  width: 100%; 
}

.table-body-container table {
  table-layout: fixed; /* Ensures that the table cells have fixed widths */
  width: 100%; /* Table should fill the width of the container */
}

.tbody {
  width: 100%; /* Full width for table content */
}

.table-body-container thead, tbody {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.table-body-container tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.table-body-container thead {
padding-right: 10px;
}

.table-body-container th:nth-child(1) {
  width: 10%;
}
.table-body-container th:nth-child(2) {
  width: 13%;
}
.table-body-container th:nth-child(3) {
  width: 30%;
  text-align: center;
}
.table-body-container th:nth-child(4) {
  width: 35%;
}
.table-body-container th:nth-child(5) {
  width: 5%;
}

.table-body-container td:nth-child(1) {
  width: 10%;
  text-align: center;
}
.table-body-container td:nth-child(2) {
  width: 13%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-body-container td:nth-child(3) {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-body-container td:nth-child(4) {
  width: 35%;
  justify-content: flex-end;
  padding-right: 15px;
}
.table-body-container td:nth-child(5) {
  width: 10%;
}

@media screen and (max-width: 1024px) {
  .agreement {
    font-size: 20px;
  }
  .input-checkbox {
    width: 16px;
    height: 16px;
  }
  .table-body-container td:nth-child(5) {
    padding-right: 0;
    width: 12%;
  }
  .table-body-container td:nth-child(4) {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 960px) {
  .agreement {
    font-size: 18px;
  }
  .input-checkbox {
    width: 16px;
    height: 16px;
  }

}
@media screen and (max-width: 768px) {
  .agreement {
    font-size: 16px;
  }
  .input-checkbox {
    width: 14px;
    height: 14px;
  }
  .table-body-container td:nth-child(4) {
    padding-right: 0;
    width: 35%;
    
  }
  .table-body-container td:nth-child(3) {
    padding-right: 0;
    width: 25%;
  }
  .table-body-container td:nth-child(2) {
    padding-right: 0;
    width: 10%;
  }
  .table-body-container td:nth-child(5) {
    padding-right: 0;
    width: 12%;
    
  }
}

.scroll-top-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25% 25px 25px 25px;
}

@media screen and (max-width: 1024px) {
    .scroll-top-container {
      position: absolute;
      bottom: 0;
      width: 100%;   
    }
  }

.gif-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--main-green);
  font-size: 24px;
}

@media screen and (max-width: 1024px) {
  .gif-container {
    font-size: 22px;
  }
}
@media screen and (max-width: 960px) {
    .gif-container {
        font-size: 20px;
      }
}
@media screen and (max-width: 768px) {
    .gif-container {
        font-size: 20px;
      }
}

.arrow-up-gif {
  width: 400px;
  height: 200px;
}

@media screen and (max-width: 1024px) {
  .scroll-top-container {
    flex-direction: column-reverse;
  }

  .arrow-up-gif {
    width: 100%;
    height: 150px;
  }
}

.box-svg {
  width: 800px;
  height: 400px;
}

@media screen and (max-width: 1024px) {
  .box-svg {
    width: 500px;
    height: 250px;
  }
}
@media screen and (max-width: 960px) {
  .box-svg {
    width: 400px;
    height: 200px;
  }
  .cart-panel-table-header {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .box-svg {
    width: 300px;
    height: 150px;
  }
}