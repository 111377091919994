.product-list-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  justify-content: center;
  gap: 5px;
  position: relative;
  padding-bottom: 16px;
  max-width: 100vw;
}

.pagination-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 40px;
  align-items: center;
}
.pagination-controls span {
  cursor: pointer;
}

.active-page {
  color: var(--main-green);
}

@media screen and (min-width: 768px) {
  .product-list-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 16px;
  }
}
