.label {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 12rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: white;
    border: 2px dashed;
    border-radius: 0.375rem;
    appearance: none;
    cursor: pointer;
  }
  
  .label:focus {
    outline: none;
  }
  
  .border-green-500 {
    border-color: #4CAF50;
  }
  
  .border-gray-300 {
    border-color: #D3D3D3;
  }
  
  .text-green-600 {
    color: #43A047;
  }
  
  .text-gray-600 {
    color: #757575;
  }
  
  .span-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .icon {
    width: 1.75rem;
  }
  
  .input {
    display: none;
  }

  .hidden {
    display: none;
  }

  .import-button {
    position: absolute;
    right: 0;
    margin-top: 50px;
  }

  .list-image-item {

    border: 1px dashed var(--admin-primary-color);
}

.import-products-button {
    margin-top: 30px;
    width: 100%;
    margin-left: 0;
}