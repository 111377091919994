.product-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 220px;
  border-radius: 16px;
  padding: 16px;
  overflow: hidden;
  gap: 8px;
  transition: background-color 0.5s ease-out;
}

.filter-button {
  display: flex;
  gap: 8px;
  max-width: 110px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 20px;
  background-color: var(--main-white);
  cursor: pointer;
  margin: 5px 0;
}

.filter-button span {
  line-height: 17px;
}

.product-catalog-img {
  width: 100%;
  height: 200px;
}

.product-description {
  line-height: normal;
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .product-catalog-img {
    height: 175px;
  }
}

.highlighted {
  background-color: var(--main-white);
}

.expand-button {
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;
}

.content {
  overflow: hidden;
  transition:
    max-height 0.5s ease-out,
    opacity 0.5s;
  max-height: 0;
  opacity: 0;
  text-align: left;
  margin-top: 10px;
}

.expanded {
  height: auto;
  opacity: 1;
}

.content {
  height: auto;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product-details {
  width: 100%;
}

.details-dropdown {
  text-align: right;
}

.details-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.details-button svg {
  transition: transform 0.5s ease-out;
  display: inline-block;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}

.points-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .product-label {
    min-height: 36px;
  }
  .product-card-container {
    width: 220px;
    max-width: none;
  }
}
@media screen and (min-width: 1024px) {
  .product-label {
    min-height: 45px;
  }
  .product-card-container {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .product-card-container {
    width: 230px;
  }
}
