.addToCart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100px;
    max-width: fit-content;
    min-height: 35px;
    gap: 5px;
    width: 95%;
}

.addToCart__buttons {
    position: relative;
    border-radius: 20px;
    width: 35px;
    height: 30px;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

button.addToCart__buttons--disabled {
    background-color: #979797;
    cursor: inherit;
}

.addToCart__buttons--quantity {
    background-color: #2F929D;
}

.addToCart__buttons--quantity > svg {
    width: 25px !important;
    height: 20px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.addToCart__buttons--bin {
    background-color: var(--main-button-color);
}
.addToCart__buttons--bin > svg {
    width: 25px !important;
    height: 20px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.addToCart__input {
    border: 1px solid var(--main-button-color);
    border-radius: 4px;
    padding: 4px 0 4px 4px;
    font-weight: bold;
    font-size: 11px;
    max-width: 35px;
    text-align: center;
    height: 30px;
}

@media screen and (min-width: 768px) {
    .addToCart {
        gap: 8px;
    }
}

@media screen and (min-width: 960px) {
    .addToCart {
        max-width: 180px;
        margin: 0 auto;
    }

    .addToCart__buttons {
        width: 35px;
        height: 30px;
    }

    .addToCart__input {
        max-width: 45px;
        padding: 4px;
        font-size: inherit;
    }
}
@media screen and (max-width: 960px) {
    .addToCart__buttons-product {
        width: 30px !important;
        height: 30px !important;
    }
}
