.add-image-container {
    width: 100%;
    height: 100%;
    max-width: 50vw;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
}

.current-image-container {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
}

.divider {
    width: 80%;
    height: 2px;
    background-color: var(--admin-primary-color);
    margin: 20px;
}

.current-image-text {
    width: 40%;
    font-size: 20px;
    font-family: var(--main-font-family);
}

.image-container {
    width: 60%;
}

.biblio-text {
    font-size: 20px;
    font-family: var(--main-font-family);
    margin: 10px;
}

.image-item {
    width: 150px;
    height: 150px;
    margin: 10px;
    border: 1px dashed var(--admin-primary-color);
}

@media screen and (max-width: 1280px) {
    .image-item {
        width: 100px;
        height: 100px;
        margin: 10px;
        border: 1px dashed var(--admin-primary-color);
    }
  }

.actual-image-item {
    width: 250px;
    height: 250px;
    margin: 10px;
    border: 1px dashed var(--admin-primary-color);
}

@media screen and (max-width: 1280px) {
    .actual-image-item {
        width: 100px;
        height: 100px;
        margin: 10px;
        border: 1px dashed var(--admin-primary-color);
    }
  }

.selected-image-item {
    border: 3px solid var(--admin-primary-color);
}

.update-image-button {
position: absolute;
right: 0;
bottom: 0;
background-color: red;
}

.image-selection-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 40%;
    overflow: auto;
}

.add-image-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}