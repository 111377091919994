.admin-button {
    border-radius: 20px;
    min-width: 100px;
    min-height: 35px;
    padding: 0 30px;
    color: var(--main-white);
    background-color: var(--admin-primary-color);
    border: none;
    cursor: pointer;
    margin: 5px 10px;
}