.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  min-width: 60%;
  min-height: 40%;
  display: flex;
  flex-direction: column;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 25px;
  cursor: pointer;
}

.modal-title {
  border-bottom: 1px solid var(--admin-primary-color);
  padding: 15px;
  font-size: 22px;
  font-family: var(--main-font-family);
  flex-shrink: 0; 
}

.modal-body {
  margin: 10px;
  padding: 10px;
  max-height: 100vh;
  max-width: 80vw;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
