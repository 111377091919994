.confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .confirmation-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 30%;
    height: 20%;
    min-height: 200px;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .confirmation-modal-title {
    padding: 10px;
    font-size: 20px;
    font-family: var(--main-font-family);
    font-weight: 700;
    flex-shrink: 0; 
  }
  
  .confirmation-modal-body {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
  }

  .modal-buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 100px;
  }
  