.cart-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.cart-card__info-package {
  padding: 0 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
}

.package-cart-img {
  width: 40px;
  height: auto;
}

.image {
  width: 15%;
  text-align: center;
}

.code {
  width: 20%;
  font-size: 16px;
}

.name {
  width: 30%;
  font-size: 16px;
}

.quantity {
  width: 20%;
}

.points {
  width: 5%;
}

.points > svg {
  width: 50px;
}

.points__icon {
  position: relative;
  display: flex;
}

.points__icon--span {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #fff;
}

.points__icon--total {
  top: 18px;
  font-weight: 600;
}

.points__icon--points {
  top: 30px;
  font-weight: 700;
  font-size: 9px;
}

@media screen and (min-width: 980px) {
  .package-cart-img,
  .points__icon {
    width: 60px;
  }

  .image {
    width: 10%;
    text-align: right;
  }

  .code {
    width: 15%;
    font-weight: 600;
    text-align: center;
  }

  .name {
    width: 30%;
  }

  .quantity {
    width: 25%;
  }

  td.quantity {
    font-size: 14px;
  }

  .points {
    width: 5%;
  }

  .points > svg {
    width: 60px;
  }

  .points__icon--total {
    top: 15px;
  }

  .points__icon--points {
    font-size: 11px;
  }
}

.package-details-button {
  padding: 0 !important;
  cursor: pointer;
  text-decoration: underline;
}

.cart-card__action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 1280px) {
  .name {
    font-size: 12px;
  }
  .code {
    font-size: 12px;
  }
  .cart-card__info-package {
    padding: 0 0 20px;
    padding-right: 15%;
  }

  .points__icon {
    width: 50px !important;
    font-size: 10px !important;
  }
  .points > svg {
    width: 55px !important;
    height: 55px !important;
  }
}

@media screen and (max-width: 960px) {
  .name {
    font-size: 12px;
  }
  .code {
    font-size: 12px;
  }
  .cart-card__info-package {
    padding: 0 0 15px;
    padding-right: 10%;
    flex-direction: column-reverse;
  }
  
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 10px;
  }
  .code {
    width: 20%;
    font-size: 8px;
  }
  .cart-card__info-package {
    padding: 0 0 15px;
    padding-right: 0;
  }
}

.golden-ticket-cart {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
