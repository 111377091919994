
.packages-container-list {
 overflow: auto;
 height: 85vh;
}

.actions-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.rank-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    width: 60%;
    text-align: center;
    border: 1px solid black;
    
}
.rank-input::-webkit-inner-spin-button,
.rank-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.rank-input {
  -moz-appearance: textfield;
}

.bulk-delete-button {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
    appearance: none;
    border: 2px solid #ccc;
    background-color: #fff;
  }
  
  input[type="checkbox"]:checked {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  input[type="checkbox"]:checked::after {
    content: '✔';
    font-size: 12px;
    color: white;
    display: block;
    text-align: center;
    line-height: 12px;
  }
  