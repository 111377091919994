.package-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 220px;
  border-radius: 16px;
  padding: 16px;
  overflow: hidden;
  gap: 8px;
  transition: background-color 0.5s ease-out;
}

.filter-button {
  display: flex;
  gap: 8px;
  max-width: 110px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 20px;
  background-color: var(--main-white);
  cursor: pointer;
  margin: 5px 0;
}

.filter-button span {
  line-height: 17px;
}

.package-catalog-img {
  width: 100%;
  height: 200px;
}

.package-description {
  line-height: normal;
  text-align: justify;
}

@media screen and (max-width: 1024px) {
  .package-catalog-img {
    height: 175px;
  }
}

.highlighted {
  background-color: var(--main-white);
  justify-content: space-between;
}
.highlighted-product {
  background-color: var(--main-white);
  justify-content: flex-start;
}

.expand-button {
  background: none;
  border: none;
  text-align: center;
  cursor: pointer;
}

.content {
  overflow: hidden;
  transition:
    max-height 0.5s ease-out,
    opacity 0.5s;
  max-height: 0;
  opacity: 0;
  text-align: left;
  margin-top: 10px;
}

.expanded {
  height: auto;
  opacity: 1;
}

.content {
  height: auto;
  opacity: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.package-details {
  width: 100%;
}

.details-dropdown {
  text-align: right;
}

.details-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.details-button svg {
  transition: transform 0.5s ease-out;
  display: inline-block;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease-out;
}

.points-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .package-label {
    min-height: 36px;
  }
  .package-card-container {
    width: 220px;
    max-width: none;
  }
}
@media screen and (min-width: 1024px) {
  .package-label {
    min-height: 45px;
  }
  .package-card-container {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .package-card-container {
    width: 260px;
  }
}

@media screen and (max-width: 768px) {
  .points__icon--total {
    left: 0% !important;
    top: 12px !important;
  }
  .points__icon--points-package {
    left: 1px !important;
    top: 25px !important;
  }
}
.package-products-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 10px;
  padding-bottom: 2px;
  max-height: 150px;
  min-height: 20px;
  overflow-y: auto;
}

.package-products-item {
  display: flex;
  justify-content: flex-start;
  font-size: 9px;
  line-height: 14px;
  font-family: var(--main-font-family);
  font-weight: 600;
  gap: 1px;
  position: relative;
  margin-bottom: 5px;
}

.package-products-item::before {
  content: '•'; /* Add bullet point manually */
  margin-right: 3px; /* Space between bullet and text */
  font-size: 14px; /* Adjust size of bullet */
  color: black; /* Adjust color of bullet */
  line-height: 14px;
}

.package-products-item span:first-of-type {
  width: 20px;
}

.points-package {
  width: 30% !important;
  padding: 15px 0 !important;
}

.points__icon-package {
  width: 40px !important;
}
.points-package-icon {
  width: 60px !important;
}

.points__icon-package > span {
  left: 23%;
  font-size: 16px;
}

.points__icon--points-package {
  font-size: 10px !important;
  left: 25%;
}


@media screen and (max-width: 1280px) { 
  .points__icon-package > span {
    left: 0;
    font-size: 14px;
    top: 14px;
  }

  .points-package {
    width: 40% !important;
  }
}