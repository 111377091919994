.dropdown-input-option {
height: 500px;
}

select option {
    background-color: #fff;
    color: #333;
    padding: 10px;
    font-size: 16px;
  }

  .custom-dropdown-list {
    height: 500px;
  }
