.product-catalog-container {
  display: grid;
  grid-template-areas:
    "d a b"
    "c c c";
  gap: 8px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 16px;
}

/* Mobile - Hide overflow when filter menu is open */
.product-catalog-container.menu-display {
  max-height: 100vh;
  overflow: hidden;
}

.product-filter-container {
  grid-area: a;
  display: flex;
  justify-content: flex-end;
  margin-left: -9px;
}

.info-panel-cart-container {
  grid-area: b;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.product-catalog {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 35px;
  grid-area: c;
  width: 100%;
  min-height: 100vh;
}

.order-history-container.desktop {
  display: none;
}
.order-history-link-header {
  display: flex;
  align-items: center;
  gap: 4px;
}
.order-history-link-header.mobile {
  grid-area: d;
  padding-left: 8px;
  justify-content: flex-end
}
.order-history-text {
  font-size: 12px;
  font-weight: 400;
}

@media screen and (min-width: 768px) {
  .order-history-text {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .product-catalog-container {
    display: flex;
  }
  .product-catalog {
    width: 60%;
  }
  .info-panel-cart-container {
    flex-direction: column;
    width: 20%;
    padding: 0 16px;
  }
  .info-panel-cart-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .order-history-container.mobile {
    display: none;
  }
  .order-history-container.desktop {
    display: flex;
    padding-left: 16px;
  }
}

.hide-history {
  display: none !important;
}

.second-cart-button {
  position: absolute;
  right: 10px;
  bottom: -60px;
  z-index: 1000;
}

@media screen and (min-width: 1024px) {
  .second-cart-button {
display: none;
  }
}

.order-history-wording {
  display: flex;
  align-items: center;
  justify-content: end;
}