
.products-container-list {
    overflow: auto;
    height: 85vh;
   }
   
   .actions-cell {
       display: flex;
       align-items: center;
       justify-content: space-between;
       gap: 5px;
   }