.audience-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.input-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputs-container {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  );
  grid-gap: 20px;
  flex-grow: 1;
  margin-bottom: 20px;
}

.input-text {
    min-height: 40px;
    border-radius: 10px;
    border: 1px solid var(--admin-primary-color);
    padding-left: 10px;
}
.input-label {
    font-size: 18px;
    font-family: var(--main-font-family);
}

.validation-error {
    font-size: 12px;
    font-family: var(--main-font-family);
    color: var(--error-color);
    padding-left: 10px;
}

.full-width {
  grid-column: span 2;
}