.order-confirmation-page {
  min-height: 100vh;
  padding: 8px;
}

.order-confirmation-mention {
  color: var(--main-green);
  font-size: 18px;
  text-align: center;
}
.point-mention {
  color: var(--main-red);
  font-size: 14px;
  text-align: center;
}

.order-confirmation-page .order-history-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 8px 16px;
}

.order-confirmation-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-white);
  border-radius: 32px;
  padding: 16px 16px 48px 16px;
  gap: 24px;
}

.order-confirmation-mention-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.order-confirmation-mention-container .point-mention {
  display: flex;
  align-items: center;
}
.order-confirmation-mention-container .points__icon {
  position: relative;
}
.order-confirmation-mention-container .points__icon--total {
  top: 18px;
  left: 0;
}
.order-confirmation-mention-container .points__icon--points {
  top: 45%;
  left: 0;
  font-size: 10px;
}
.order-confirmation-img img {
  width: 100%;
  max-width: 400px;
}

.golden-ticket-mention {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 8px;
  max-width: 80%;
}
.golden-ticket-mention svg {
  width: 56px;
  height: 56px;
}
.golden-ticket-mention-text {
  width: fit-content;
}

.order-confirmation-page .order-history-link.desktop {
  display: none;
}


.order-confirmation-container,
.order-confirmation-page .order-history-link.mobile {
  max-width: 500px;
  margin: 0 auto;
}

.no-golden-ticket-mention {
  max-width: 80%;
  text-align: center;
}


@media screen and (min-width: 1024px) {
  .order-confirmation-container {
    display: grid;
    grid-template-areas:
    "a a"
    "b c";
    flex-direction: row;
    margin-top: 48px;
    width: fit-content;
    max-width: 80%;
    gap: 0;
    padding: 16px 48px 16px 16px;
  }
  .order-confirmation-page .order-history-link {
    grid-area: a;
    justify-self: start;
  }
  .order-confirmation-img {
    grid-area: b;
  }
  .order-confirmation-mention-container {
    grid-area: c;
    gap: 40px;
  }
  .order-confirmation-mention,
  .point-mention,
  .golden-ticket-mention {
    margin-right: auto;
  }
  .order-confirmation-page .order-history-link.mobile {
    display: none;
  }
  .order-confirmation-page .order-history-link.desktop {
    display: flex;
    padding-bottom: 0;
  }
  .order-confirmation-page .order-history-link-label {
    font-size: 16px;
  }
  .order-confirmation-page .order-history-link svg {
    width: 40px;
    height: 40px;
  }
  .order-confirmation-mention {
    font-size: 24px;
  }
  .point-mention {
    font-size: 18px;
  }
  .no-golden-ticket-mention {
    max-width: 450px;
    padding-right: 16px;
    text-align: start
  }
  .point-mention .points__icon svg {
    height: 72px;
    width: 72px;
  }
  .order-confirmation-mention-container .points__icon--total {
    top: 22px;
  }
}
