.info-panel.is-active {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7)
}

.info-panel-container {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: var(--main-white);
    padding: 32px;
    margin-top: 20%;
    border-radius: 16px;
    gap: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.info-panel-container.is-active {
    max-width: 600px;
    height: fit-content;
    position: relative;
    display: flex;
    margin-top: 0;
}

.close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.info-panel-icon {
    margin-left: 8px;
    cursor: pointer;
}

@media screen and (min-width: 1024px) {
    .info-panel-container {
        display: flex;
        margin: 30px 48px 30px 0;
    }
    .close-icon,
    .info-panel-icon,
    .filter-button {
        display: none;
    }
}
