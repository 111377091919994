.order-detail-array {
  width: 100%;
  font-weight: 600;
}
.order-detail-quantity {
  text-align: center;
  padding-right: 8px;
  width: 25%;
}
.order-detail-status {
  width: 25%;
}
.order-detail-status-title {
  text-align: start;
}
.order-detail-status.delivered {
  color: var(--success-color);
}
.order-detail-status.shipped {
  color: var(--main-orange);
}
.order-detail-status.canceled {
  color: var(--main-red);
}
.product-cart-img-container {
  text-align: center;
  width: 10%;
}

.order-detail-ref {
  width: 25%;
}

.order-detail-label {
  width: 25%;
}

@media screen and (min-width: 980px) {
  .order-detail-ref {
    text-align: center;
  }
}

.history-details-body {
  display: contents;
}