.order-list-page {
  padding: 16px;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
}

.order-list-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.order-list-page-header .main-link {
  justify-self: flex-end;
}

.order-list-date-filter {
  justify-self: center;
  max-width: 330px;
}

.order-list-page-header .main-link svg {
  width: 24px;
  height: 24px;
}

.order-history-link-label {
  display: none;
}

.order-list-page-header .link-label {
  font-size: 10px;
}

.date-filter-button {
  display: flex;
  gap: 8px;
  max-width: 110px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  border-radius: 20px;
  background-color: var(--main-white);
  cursor: pointer;
  border: 1px solid #e0e0e0;
  font-weight: 600;
}

.hide-filters {
  display: none;
}

.hide-filters-responsive {
  transform: rotate(180deg);
  display: block;
  cursor: pointer;
}

/* Order List Container */
.order-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;
}

.order-error-message {
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin-top: 40px;
  text-align: center;
}

.order-list-date-filter {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.order-list-element {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
}

.order-list-element:last-child {
  margin: 0 auto;
}

.input-date {
  padding: 5px 20px 5px 5px;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
}

.order-list-filter-search {
  cursor: pointer;
}

.order-list-filter-search>svg {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 480px) {
  .order-history-link {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .order-list-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hide-filters {
    display: block;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  .hide-filters-responsive {
    display: none;
  }

  .order-list-page-header .link-label {
    font-size: 12px;
  }

  .order-list-page-header .main-link svg {
    width: 32px;
    height: 32px;
  }

  .order-list-date-filter {
    max-width: unset;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 960px) {  
  .order-list-page-header,
  .order-list-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1024px) {
  .order-history-link, .order-history-link-label {
    display: flex;
  }
  .order-list-history-link {
    align-items: center;
  }
}