.product-filter {
  display: none;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 10;
}

.product-filter.is-active {
  display: flex;
  top: 0;
  left: 0;
  height: fit-content;
  width: 100vw;
  background-color: var(--main-grey);
  box-shadow: 0 20px 30px rgba(0, 0.3, 0, 0.3);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.product-filter.is-active .filter-menu-container {
  padding-bottom: 32px;
}

.filter-menu-container {
  display: flex;
  width: 80%;
  max-width: 320px;
  flex-direction: column;
  padding: 32px 0 0 32px;
  gap: 8px;
}

.filter-header {
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.filter-header span {
  display: flex;
  align-items: flex-end;
  height: 32px;
}

.filter-reference {
  margin-top: 32px;
  cursor: pointer;
}
.filter-reference,
.filter-star-product {
  font-size: 16px;
  padding: 8px 16px;
}
.filter-star-product,
.filter-brand-header {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.brand-filter-container {
  border-radius: 16px;
  padding: 8px 16px 32px 16px;
}
.brand-filter-container.active {
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-out
}

.brand-filter-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  padding-left: 16px
}

.brand-filter {
  text-transform: capitalize;
  cursor: pointer;
}
.brand-filter-active {
  color: var(--main-green);
  transition: 0.2s all ease;
}

.active-filter {
  color: var(--main-green);
}

.close-filter-menu-icon {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .filter-menu-container {
    padding: 24px;
    width: 100%;
    max-width: none;
  }
  .brand-filter:hover,
  .filter-reference:hover {
    color: var(--main-green);
    transition: 0.2s all ease-out;
  }
  .product-filter {
    display: flex;
    min-width: 20%;
  }
  .close-filter-menu-icon {
    display: none;
  }
}
