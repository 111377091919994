.expanded-row-highlight {
  background-color: var(--admin-primary-color); /* Light blue color */
  color: white;
  height: 120px;
}
.expanded-row {
  height: 120px;
}

.expanded-row-content {
  padding: 10px;
  background-color: #f9f9f9;
  margin: 5px 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  flex-direction: column;
}

.product-table {
  border-collapse: collapse;
  margin-top: 10px;
  border: 1px solid black;
  min-width: 75vw;
}

.product-table th,
.product-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid black;
}

.product-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: black;
  border-top: 1px solid black !important;
}

.product-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.product-table tr:hover {
  background-color: #ddd;
}

.generic-table-package {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: var(--main-font-family);
  font-size: 14px;

  border: 1px solid var(--admin-primary-color);
}

.generic-table-package th,
.generic-table-package td {
  text-align: center;
  padding: 12px 10px;
  border-bottom: 1px solid var(--admin-primary-color);
}

.generic-table-package th {
  background-color: var(--admin-primary-color);
  color: var(--main-white);
}

/* .generic-table-package tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.generic-table-package tbody tr:hover {
  background-color: #ddd;
  cursor: default;
}
 */

.generic-table-package th:last-child,
.generic-table-package td:last-child {
  border-right: none;
}

.generic-table-package thead th {
  border-top: 1px solid #f0f0f0;
}

.generic-table-package {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.table-cell-package {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.generic-table-package td {
  position: relative;
  height: 80px;
  min-width: 150px;
  overflow: hidden;
}

.remove-product-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.remove-product-button:hover {
  background-color: #ff7875;
}

.add-product-button {
  background-color: #4caf50;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}

.add-product-button:hover {
  background-color: #45a049;
}

.cancel-btn-package {
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
  text-align: center;
  background-color: red;
  color: white;
}
.add-btn-package {
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  background-color: green;
  color: white;
  padding: 5px;
}

.action-column {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.package-toggle-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.package-table-admin {
  display: table-row-group;

}

