.main-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 32px;
    padding: 8px 32px;
    color: var(--main-white);
    background-color: var(--main-button-color);
    border: none;
    cursor: pointer;
    position: relative;
}