.forbidden-order-page {
  background-color: var(--main-grey);
  min-height: 100vh;
  padding: 8px;
}

.forbidden-order-title {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.forbidden-order-mention {
  color: var(--main-green);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.forbidden-order-page .order-history-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 8px 16px;
}

.forbidden-order-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--main-white);
  border-radius: 32px;
  padding: 30px 30px 48px 30px;
  gap: 24px;
  text-align: center;
}

.forbidden-order-mention-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.forbidden-order-img img {
  width: 85%;
  max-width: 400px;
}

.forbidden-order-page .order-history-link.desktop {
  display: none;
}


.forbidden-order-container,
.forbidden-order-page .order-history-link.mobile {
  max-width: 500px;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .forbidden-order-container {
    display: grid;
    grid-template-areas:
    "a a"
    "b c";
    flex-direction: row;
    margin-top: 48px;
    width: fit-content;
    max-width: 65%;
    gap: 0;
    padding: 30px 120px 30px 30px;
    text-align: start;
  }
  .forbidden-order-page .order-history-link {
    grid-area: a;
    justify-self: start;
  }
  .forbidden-order-img {
    grid-area: b;
  }
  .forbidden-order-mention-container {
    grid-area: c;
    gap: 40px;
  }
  .forbidden-order-title, .forbidden-order-mention {
    margin-right: auto;
  }
  .forbidden-order-page .order-history-link.mobile {
    display: none;
  }
  .forbidden-order-page .order-history-link.desktop {
    display: flex;
    padding-bottom: 0;
  }
  .forbidden-order-page .order-history-link-label {
    font-size: 16px;
  }
  .forbidden-order-page .order-history-link svg {
    width: 40px;
    height: 40px;
  }
  .forbidden-order-title, .forbidden-order-mention {
    font-size: 22px;
  }
}
